import React, { useState } from "react";
import { Form, FieldArray, Field, Formik } from "formik";
import {
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { Select } from "antd";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import FormikTextField from "../shared/TextField";
import FormikAutocomplete from "../shared/AutoComplete";
import FormikSelect from "../shared/Select";
import { getUserEmail } from "../../helpers/utility";
import { get } from "lodash";
import ErrorPopup from "../shared/ErrorPopup";
import moment from "moment";
import { AppButton, PrimaryButton } from "../RankTracking/style";
import CustomToolTip from "../shared/CustomToolTip";

const { Option } = Select;
const ManualUploadForm = (props) => {
  const {
    frequencies,
    engines,
    locales,
    tags,
    selectedProject,
    isManualUploadSuccess,
    isLoadingPage,
    isLoading,
    devices,
    dispatchManualUploadSetFalse,
    isFailedManualUpload,
  } = props;
  const [initialValues, setInitialValues] = useState({
    keywords: [""],
    tags: [""],
    properties: {
      locale: "",
      geo: "",
      engine: "",
      frequency: "",
      device: "",
      user_email: getUserEmail(),
      submitted_date: "",
      project_id: get(selectedProject, "project_id", ""),
      address: "",
    },
  });

  const [formData, setFormData] = useState();
  const [selectedEngine, setSelectedEngine] = useState("");
  const [errorData, setErrorData] = useState("");
  const [validData, setValidData] = useState("");
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const [count, setCount] = useState([]);
  const [errorPopupIndex, setErrorPopupIndex] = useState();
  const [ignoreErrors, setIgnoreError] = useState([false]);
  const projectTagsOption = [];
  const [uploadMore, setUploadMore] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const [uploadData, setUploadData] = useState(false);
  const [arrayHelpers, setArrayHelpers] = useState([""]);

  let errors = { keywords: [], properties: { frequency: "", device: "" } };
  let keywordCount = [];
  if (tags) {
    for (let i = 0; i < tags.length; i++) {
      projectTagsOption.push(<Option key={tags[i]}>{tags[i]}</Option>);
    }
  }
  const keywordRegex = /^[a-zA-Z0-9( ),]*$/i;

  const showError = (errorData, validData = "", index = null) => {
    setErrorData(errorData);
    setValidData(validData);
    setErrorPopupOpen(true);
    setErrorPopupIndex(index);
  };

  const alterIngnoreErrors = () => {
    ignoreErrors[errorPopupIndex] = true;
    setIgnoreError(ignoreErrors);
    errors.keywords[errorPopupIndex] = "";
    // setInitialValues(formData?.keywords);
  };

  const handleSubmit = () => {
    setUploadMore(false);
    setUploadData({
      submitted_date: moment(new Date()).utc().format("MM-DD-YYYY HH:mm:SS"),
      upload_method: "manual_edit",
    });
  };

  return (
    <>
      {isLoadingPage || isLoading ? (
        <>
          <CircularProgress />
          {isLoading && (
            <p className="loading-message center">Do not close this window.</p>
          )}
        </>
      ) : isManualUploadSuccess && !uploadMore ? (
        <div className="center">
          <p className="loading-message">
            <img className="success-icon" src="/images/Icon_Success.svg" alt="success" />
            Upload complete. Your scoreboard will update in 90 minutes or less.
            <span
              className="upload-more-button"
              onClick={() => {
                setUploadMore(true);
              }}
            >
              {" "}
              Upload more search terms
            </span>
          </p>
        </div>
      ) : isFailedManualUpload && !uploadMore ? (
        <div className="center">
          <p className="loading-message">
            <img
              className="success-icon"
              alt="success-icon"
              src="/images/Alert_Icon.svg"
            />
            <div>
              Upload{" "}
              <span
                className="upload-more-button"
                onClick={() => {
                  showError("Unable to upload keywords");
                  setIsUploadError(true);
                }}
              >
                failed with errors
              </span>{" "}
              for keywords via Manual Upload.
            </div>
            <span
              className="upload-more-button"
              onClick={() => {
                setUploadMore(true);
              }}
            >
              Try again
            </span>
          </p>
        </div>
      ) : (
        <>
          <div className="searchTermHeading">Select tracking settings</div>
          <Formik
            initialValues={initialValues}
            validateOnChange={true}
            validateOnBlur={true}
            enableReinitialize
            validate={(values) => {
              setFormData(values);
              if (!values.properties.frequency) {
                errors.properties.frequency = "Required";
              }
              if (!values.properties.engine) {
                errors.properties.engine = "Required";
              }
              if (!values.properties.device) {
                errors.properties.device = "Required";
              }
              if (!values.properties.locale) {
                errors.properties.locale = "Required";
              }
              values.keywords.forEach(function (keywords, index) {
                if (!keywords) {
                  errors.keywords[index] = "Required";
                } else {
                  let keywordForIndex = keywords.replace(/\n/g, ",").split(",");
                  keywordForIndex = keywordForIndex.map(
                    Function.prototype.call,
                    String.prototype.trim
                  ); // trim whitespaces from keywords
                  keywordForIndex = keywordForIndex.filter((el) => {
                    // remove empty elements from keywords
                    return el != "";
                  });
                  keywordCount[index] = keywordForIndex.length;
                  setCount(keywordCount);
                  if (keywordForIndex.length > 10000) {
                    dispatchManualUploadSetFalse();
                    errors.keywords[index] = `${keywordForIndex.length ? keywordForIndex.length : 0
                      } /10000 search terms Too many keywords`;
                  } else if (!ignoreErrors[index]) {
                    let errorKeywords = "",
                      validKeywords = "";
                    keywordForIndex.map((keyword) => {
                      if (!keyword.match(keywordRegex)) {
                        errorKeywords += keyword + ",";
                      } else {
                        validKeywords += keyword + ",";
                      }
                    });
                    if (errorKeywords) {
                      dispatchManualUploadSetFalse();
                      errors.keywords[index] = (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <p>
                          Keyword errors{" "}
                          <a
                            className="view-errors"
                            onClick={() =>
                              showError(errorKeywords, validKeywords, index)
                            }
                          >
                            view errors
                          </a>
                        </p>
                      );
                    } else {
                      errors.keywords[index] = "";
                    }
                  } else {
                    errors.keywords[index] = "";
                  }
                }
              });
              return !errors.properties.frequency && !errors.properties.engine && !errors.properties.device &&
                !errors.properties.locale && (!errors.keywords.length ||
                  errors.keywords.every((elem) => elem === ""))
                ? {}
                : errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
                let keyword_groups = [];
                values.keywords.map((keyword, index) => {
                  let keywordForIndex = keyword.replace(/\n/g, ",").split(",");
                  keywordForIndex = keywordForIndex.map(
                    Function.prototype.call,
                    String.prototype.trim
                  ); // trim whitespaces from keywords
                  keywordForIndex = keywordForIndex.filter((el) => {
                    // remove empty elements from keywords
                    return el != "";
                  });
                  keyword_groups.push({
                    keywords: keywordForIndex,
                    properties: {
                      tags: values.tags[index] ? values.tags[index] : [],
                      ...values.properties,
                    },
                  });
                });
                props.saveTags({
                  project_id: selectedProject.project_id,
                  tags: [].concat.apply([], values.tags),
                });
                props
                  .manualUpload({ keyword_groups: keyword_groups })
                  .then(setCount([]))
                  .then(setSelectedEngine(""))
                  .then(setIgnoreError([false]))
                  .then(() => setUploadMore(false));
              }, 500);
            }}
            render={({ submitForm, isSubmitting, values, setFieldValue }) => (
              <Form className="manual-form">
                <Grid container spacing={1}>
                  <Grid xs={12} sm={6} md={6} lg={5} xl={5}>
                    <span className="grid-label">
                      Frequency <span className="required">*</span>
                    </span>
                    <Field
                      name="properties.frequency"
                      label="Select frequency"
                      component={FormikAutocomplete}
                      variant="outlined"
                      options={frequencies}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} md={6} lg={5} xl={5}>
                    <span className="grid-label">
                      Engine <span className="required">*</span>
                    </span>
                    <Field
                      name="properties.engine"
                      label="Select search engine"
                      component={FormikAutocomplete}
                      onChange={(event) => {
                        setFieldValue("properties.engine", event);
                        setFieldValue("properties.locale", "");
                        setSelectedEngine(event);
                      }}
                      options={engines}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} md={6} lg={5} xl={5}>
                    <span className="grid-label">Location</span>
                    <CustomToolTip title={`We recommend setting a location for the best and most consistent results. You can track search terms at the country, state, city, or zipcode level.
                      If tracking across the US, we recommend using "Dearing, KS" as your location - its what Google Maps uses for its centroid.`} />
                    <Field
                      component={FormikTextField}
                      name="properties.geo"
                      variant="outlined"
                      singleLine={true}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} md={6} lg={5} xl={5}>
                    <span className="grid-label"> Device <span className="required">*</span></span>
                    <Field
                      name="properties.device"
                      label="Select device"
                      component={FormikAutocomplete}
                      variant="outlined"
                      options={devices}
                    />
                  </Grid>
                  <Grid xs={12} sm={6} md={6} lg={5} xl={5}>
                    <span className="grid-label">LANGUAGE-COUNTRY CODE <span className="required">*</span></span>
                    <Field
                      name="properties.locale"
                      label="Select language/country"
                      component={FormikAutocomplete}
                      variant="outlined"
                      options={
                        locales && selectedEngine ? locales[selectedEngine] : []
                      }
                    />
                  </Grid>
                </Grid>
                <div class="keyword-notes">
                  <span>Add search terms</span>
                  <ul>
                    <li>Separate search terms with commas or a new line</li>
                    <li>Search terms should be alphanumeric and less than 10 words or 80 characters - we'll let you know if we see errors!</li>
                    <li>Duplicates are OK - we'll remove any duplicates in each submission</li>
                  </ul>
                </div>
                <FieldArray
                  className="search-terms-wrapper"
                  name="keywords"
                >
                  <div className="add-keyword-wrapper">
                    {arrayHelpers?.map((keyword, index) => (
                      <div
                        key={index}
                        className="keywords-wrapper MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2"
                      >
                        <Grid xs={10} sm={5}>
                          <span className="grid-label">
                            Search Terms <span className="required">*</span>
                          </span>
                          <Field
                            component={FormikTextField}
                            name={`keywords.${index}`}
                            variant="outlined"
                            helperText={`${count[index] ? count[index] : 0
                              } / 10000 search terms`}
                            style={{ paddingLeft: "2px !important" }}
                          />
                        </Grid>

                        <Grid xs={10} sm={5}>
                          <span className="grid-label"> Tags</span>
                          <Field
                            component={FormikSelect}
                            name={`tags.${index}`}
                            label=""
                            variant="outlined"
                            mode="tags"
                            placeholder=""
                            options={projectTagsOption}
                          />
                        </Grid>
                        <Grid>
                          {index > 0 && (
                            <RemoveCircleOutlineIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                let array = [...arrayHelpers];
                                array.splice(index, 1);
                                setArrayHelpers(array);
                                values.keywords.splice(index, 1); //  remove keywords from values
                                values.tags.splice(index, 1); //  remove tags from values
                                ignoreErrors.splice(index, 1);
                              }}
                            />
                          )}
                        </Grid>
                      </div>
                    ))}
                  </div>
                </FieldArray>
                <Grid container justify="center">
                  <Grid item xs={10}>
                    <div className="manual-upload-footer colorblack">
                      <AppButton
                        type="button"
                        onClick={() => {
                          setArrayHelpers(oldArray => [...oldArray, ""]);
                          ignoreErrors.push(false);
                        }}
                        style={{ backgroundColor: "#FFFFFF" }}
                      >
                        Add more search terms
                        <img
                          src={"/images/Black-Arrow.png"}
                          alt="Black arrow icon"
                        />
                      </AppButton>
                      <PrimaryButton
                        disabled={isSubmitting}
                        onClick={() => {
                          submitForm();
                          handleSubmit();
                        }}
                      >
                        Submit search terms
                        <img
                          src={"/images/Icon_Arrow-White.png"}
                          alt="Black arrow icon"
                        />
                      </PrimaryButton>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          />
        </>
      )}
      <ErrorPopup
        errorData={errorData}
        validData={validData}
        errorPopupOpen={errorPopupOpen}
        setErrorPopupOpen={setErrorPopupOpen}
        okText="IGNORE ERRORS"
        cancelText="RETURN TO FIX ERRORS"
        footer={!isUploadError}
        errorPopupIndex={errorPopupIndex}
        alterIngnoreErrors={alterIngnoreErrors}
        uploadData={isUploadError ? uploadData : null}
      />
    </>
  );
};

export default ManualUploadForm;
