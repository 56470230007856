import {
  API,
  API_FETCH_METADATA,
  API_FETCH_NOTIFICATIONS,
  API_FETCH_ORGANIZATIONS,
  API_FETCH_DEPARTMENTS,
  API_PROJECT,
  API_SHARE,
  API_AUTH,
  API_FETCH_FREQUENCY,
  API_FETCH_LANG,
  API_FETCH_ENGINE,
  API_TAGS,
  API_NOTIFICATION,
  API_FETCH_DEVICES,
  API_FETCH_ACCESSIBLE_CLIENTS
} from "../../api";
import * as ACTION from "../actionTypes";
import {
  getUserEmail,
  getFirebaseToken,
  clearAll
} from "../../helpers/utility";
import { store } from "../store";
import { get } from "lodash";

export function fetchNotification() {
  return dispatch => {
    dispatch(fetchNotificationBegin());
    return API.get(`${API_FETCH_NOTIFICATIONS}&notification_type=all`, {})
      .then(res => {
        dispatch(fetchNotificationSuccess(res.data?.data));
      })
      .catch(err => {
        dispatch(fetchNotificationError(err));
      });
  };
}

export const fetchNotificationBegin = () => ({
  type: ACTION.FETCH_NOTIFICATION_BEGIN
});

export const fetchNotificationSuccess = list => ({
  type: ACTION.FETCH_NOTIFICATION_SUCCESS,
  payload: list
});

export const fetchNotificationError = () => ({
  type: ACTION.FETCH_NOIFICATION_ERROR,
  error: "Unable to load notifications! please try after sometime."
});

export function fetchMetadata(user) {
  return dispatch => {
    dispatch(fetchMetadataBegin());
    return API.get(`${API_FETCH_METADATA}?email=${user}`, {})
      .then(res => {
        dispatch(fetchMetadataSuccess(res.data?.data?.metadata));
        // dispatch(fetchOrganization());
      })
      .catch(err => {
        dispatch(fetchMetadataError(err));
      });
  };
}

export const fetchMetadataBegin = () => ({
  type: ACTION.FETCH_METADATA_BEGIN
});

export const fetchMetadataSuccess = list => ({
  type: ACTION.FETCH_METADATA_SUCCESS,
  payload: list
});

export const fetchMetadataError = () => ({
  type: ACTION.FETCH_METADATA_ERROR,
  error: "Unable to load metadata! please try after sometime."
});

export function fetchOrganization() {
  return dispatch => {
    dispatch(fetchOrganizationBegin());
    return API.get(`${API_FETCH_ORGANIZATIONS}`, {})
      .then(res => {
        localStorage.setItem("clients", JSON.stringify(res.data?.data?.organizations));
        dispatch(
          fetchOrganizationSuccess(res.data?.data?.organizations, ACTION.FETCH_ORGANIZATION_SUCCESS)
        );
        dispatch(fetchDepartment());
      })
      .catch(err => {
        dispatch(fetchOrganizationError(err));
      });
  };
}

export const fetchOrganizationBegin = () => ({
  type: ACTION.FETCH_ORGANIZATION_BEGIN
});

export const fetchOrganizationSuccess = (list, type) => ({
  type: type,
  payload: list
});

export const fetchOrganizationError = () => ({
  type: ACTION.FETCH_ORGANIZATION_ERROR,
  error: "Unable to load clients! please try after sometime."
});
// --------------------------------------------------------
export function fetchAccessibleClients(user) {
  return dispatch => {
    dispatch(fetchAccessibleClientsBegin());
    return API.get(`${API_FETCH_ACCESSIBLE_CLIENTS}?email=${user}`, {})
      .then(res => {
        localStorage.setItem("accessible_clients", JSON.stringify(res.data?.data?.organizations));
        dispatch(
          fetchAccessibleClientsSuccess(res.data?.data?.organizations, ACTION.FETCH_ACCESSIBLE_CLIENTS_SUCCESS)
        );
      })
      .catch(err => {
        dispatch(fetchAccessibleClientsError(err));
      });
  };
}

export const fetchAccessibleClientsBegin = () => ({
  type: ACTION.FETCH_ACCESSIBLE_CLIENTS_BEGIN
});

export const fetchAccessibleClientsSuccess = (list, type) => ({
  type: type,
  payload: list
});

export const fetchAccessibleClientsError = () => ({
  type: ACTION.FETCH_ACCESSIBLE_CLIENTS_ERROR,
  error: "Unable to load clients! please try after sometime."
});
// --------------------------------------------------------

export function fetchDevices() {
  return dispatch => {
    dispatch(fetchDevicesBegin());
    return API.get(`${API_FETCH_DEVICES}`, {})
      .then(res => {
        dispatch(fetchDevicesSuccess(res.data?.data?.devices, ACTION.FETCH_DEVICE_SUCCESS));
      })
      .catch(err => {
        dispatch(fetchDeviceError(err));
      });
  };
}

export const fetchDevicesBegin = () => ({
  type: ACTION.FETCH_DEVICE_BEGIN
});

export const fetchDevicesSuccess = (list, type) => ({
  type: type,
  payload: list
});

export const fetchDeviceError = () => ({
  type: ACTION.FETCH_DEVICE_ERROR,
  error: "Unable to load devices! please try after sometime."
});

export function fetchDepartment() {
  return dispatch => {
    dispatch(fetchDepartmentBegin());
    return API.get(`${API_FETCH_DEPARTMENTS}`, {})
      .then(res => {
        localStorage.setItem("divisions", JSON.stringify(res.data?.data?.departments));
        dispatch(
          fetchDepartmentSuccess(res.data?.data?.departments, ACTION.FETCH_DEPARTMENT_SUCCESS)
        );
      })
      .catch(err => {
        dispatch(fetchDepartmentError(err));
      });
  };
}

export const fetchDepartmentBegin = () => ({
  type: ACTION.FETCH_DEPARTMENT_BEGIN
});

export const fetchDepartmentSuccess = (list, type) => ({
  type: type,
  payload: list
});

export const fetchDepartmentError = () => ({
  type: ACTION.FETCH_DEPARTMENT_ERROR,
  error: "Unable to load divisions! please try after sometime."
});

export function fetchProjects(user) {
  return dispatch => {
    dispatch(fetchProjectsBegin());
    return API.get(`${API_PROJECT}?email=${user}`, {})
      .then(res => {
        dispatch(fetchProjectsSuccess(res.data?.data?.projects));
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(fetchProjectsError(err));
      });
  };
}

export const fetchProjectsBegin = () => ({
  type: ACTION.FETCH_PROJECT_BEGIN
});

export const fetchProjectsSuccess = list => ({
  type: ACTION.FETCH_PROJECT_SUCCESS,
  payload: list
});

export const fetchProjectsError = () => ({
  type: ACTION.FETCH_PROJECT_ERROR,
  error: "Unable to load projects! please try after sometime."
});

export function saveProject(project) {
  return dispatch => {
    dispatch(saveProjectBegin());
    return API.post(`${API_PROJECT}`, project, {})
      .then(res => {
        dispatch(saveProjectSuccess(res?.data?.data));
      })
      .catch(err => {
        dispatch(saveProjectError(err));
      });
  };
}

export const saveProjectBegin = () => ({
  type: ACTION.SAVE_PROJECT_BEGIN
});

export const saveProjectSuccess = list => ({
  type: ACTION.SAVE_PROJECT_SUCCESS,
  payload: list
});

export const saveProjectError = () => ({
  type: ACTION.SAVE_PROJECT_ERROR,
  error: "Unable to save projects! please try after sometime."
});

export function share(data) {
  return dispatch => {
    dispatch(shareBegin());
    return API.post(`${API_SHARE}`, data, {})
      .then(res => {
        dispatch(shareSuccess(res));
      })
      .catch(err => {
        dispatch(shareError(err));
      });
  };
}

export const shareBegin = () => ({
  type: ACTION.SHARE_BEGIN
});

export const shareSuccess = message => ({
  type: ACTION.SHARE_SUCCESS,
  payload: message
});

export const shareError = () => ({
  type: ACTION.SHARE_ERROR,
  error: "Unable to share organization! please try after sometime."
});

export function deleteUser(data) {
  return dispatch => {
    dispatch(deleteUserBegin());
    return API.delete(`${API_SHARE}`, { data: data })
      .then(res => {
        dispatch(deleteUserSuccess(res));
      })
      .catch(err => {
        err.response.status === 413
          ? dispatch(
            deleteUserError("You are not authorized to delete this user")
          )
          : dispatch(
            deleteUserError(
              "Unable to delete user! please try after sometime."
            )
          );
      });
  };
}

export const deleteUserBegin = () => ({
  type: ACTION.DELETE_BEGIN
});

export const deleteUserSuccess = res => ({
  type: ACTION.DELETE_SUCCESS,
  payload: res
});

export const deleteUserError = message => ({
  type: ACTION.DELETE_ERROR,
  error: message
});

export function fetchFromStorage() {
  return dispatch => {
    dispatch(
      fetchOrganizationSuccess(
        localStorage.getItem("clients"),
        ACTION.FETCH_CACHED_ORG
      )
    );
    dispatch(
      fetchDepartmentSuccess(
        localStorage.getItem("divisions"),
        ACTION.FETCH_CACHED_DEPT
      )
    );
  };
}

export function auth() {
  return dispatch => {
    dispatch(authBegin());
    return API.post(`${API_AUTH}`, { firebase_token: getFirebaseToken() }, {})
      .then(res => {
        let now = new Date().getTime();
        res.data.jupiter_token
          ? localStorage.setItem("access_token", res.data.jupiter_token)
          : clearAll();
        localStorage.setItem("setup_time", now);
        dispatch(fetchNotification());
        dispatch(fetchMetadata());
        if (localStorage.getItem("clients")) {
          dispatch(fetchFromStorage());
        } else {
          dispatch(fetchOrganization());
        }
        dispatch(fetchProjects());
        dispatch(authSuccess(res));
      })
      .catch(err => {
        dispatch(authError("You are not authorized"));
      });
  };
}

export const authBegin = () => ({
  type: ACTION.AUTH_BEGIN
});

export const authSuccess = res => ({
  type: ACTION.AUTH_SUCCESS,
  payload: res
});

export const authError = message => ({
  type: ACTION.AUTH_ERROR,
  error: message
});

export function deleteProject(id) {
  return dispatch => {
    dispatch(deleteProjectBegin());
    return API.delete(`${API_PROJECT}/${id}`, {}, {})
      .then(res => {
        if (
          get(store.getState(), "Dashboard.selectedProject.project_id", "") ===
          id
        )
          dispatch(setSelectedProject(""));
        dispatch(deleteProjectSuccess(res));
        dispatch(fetchProjects(getUserEmail()));
      })
      .catch(err => {
        err.response.status === 413
          ? dispatch(
            deleteProjectError("You are not authorized to delete this user")
          )
          : dispatch(
            deleteProjectError(
              "Unable to delete user! please try after sometime."
            )
          );
      });
  };
}

export const deleteProjectBegin = () => ({
  type: ACTION.DELETE_PROJECT_BEGIN
});

export const deleteProjectSuccess = res => ({
  type: ACTION.DELETE_PROJECT_SUCCESS,
  payload: res
});

export const deleteProjectError = message => ({
  type: ACTION.DELETE_PROJECT_ERROR,
  error: message
});

export function fetchFrequency() {
  return dispatch => {
    dispatch(fetchFrequencyBegin());
    return API.get(`${API_FETCH_FREQUENCY}`, {})
      .then(res => {
        dispatch(
          fetchFrequencySuccess(res.data?.data?.frequencies, ACTION.FETCH_FREQUENCY_SUCCESS)
        );
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(fetchFrequencyError(err));
      });
  };
}

export const fetchFrequencyBegin = () => ({
  type: ACTION.FETCH_FREQUENCY_BEGIN
});

export const fetchFrequencySuccess = (list, type) => ({
  type: type,
  payload: list
});

export const fetchFrequencyError = () => ({
  type: ACTION.FETCH_FREQUENCY_ERROR,
  error: "Unable to load frequency! please try after sometime."
});

export function fetchEngine() {
  return dispatch => {
    dispatch(fetchEngineBegin());
    return API.get(`${API_FETCH_ENGINE}`, {})
      .then(res => {
        dispatch(fetchEngineSuccess(res.data?.data?.engines, ACTION.FETCH_ENGINE_SUCCESS));
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(fetchEngineError(err));
      });
  };
}

export const fetchEngineBegin = () => ({
  type: ACTION.FETCH_ENGINE_BEGIN
});

export const fetchEngineSuccess = (list, type) => ({
  type: type,
  payload: list
});

export const fetchEngineError = () => ({
  type: ACTION.FETCH_ENGINE_ERROR,
  error: "Unable to load engine! please try after sometime."
});

export function fetchLanguage() {
  return dispatch => {
    dispatch(fetchLanguageBegin());
    return API.get(`${API_FETCH_LANG}`, {})
      .then(res => {
        dispatch(fetchLanguageSuccess(res.data?.data?.locales, ACTION.FETCH_LANG_SUCCESS));
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(fetchLanguageError(err));
      });
  };
}

export const fetchLanguageBegin = () => ({
  type: ACTION.FETCH_LANG_BEGIN
});

export const fetchLanguageSuccess = (list, type) => ({
  type: type,
  payload: list
});

export const fetchLanguageError = () => ({
  type: ACTION.FETCH_LANG_ERROR,
  error: "Unable to load languages! please try after sometime."
});

export function fetchTags(projectId) {
  return dispatch => {
    dispatch(fetchTagsBegin());
    return API.get(`${API_TAGS}?project_id=${projectId}`, {})
      .then(res => {
        dispatch(fetchTagsSuccess(res.data?.data?.tags, ACTION.FETCH_TAGS_SUCCESS));
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
        dispatch(fetchTagsError(err));
      });
  };
}

export const fetchTagsBegin = () => ({
  type: ACTION.FETCH_TAGS_BEGIN
});

export const fetchTagsSuccess = (list, type) => ({
  type: type,
  payload: list
});

export const fetchTagsError = () => ({
  type: ACTION.FETCH_TAGS_ERROR,
  error: "Unable to load tags! please try after sometime."
});

export const setSelectedProject = project => ({
  type: ACTION.SET_SELECTED_PROJECT,
  payload: project
});

export function fetchNotifications() {
  return dispatch => {
    return API.get(`${API_NOTIFICATION}?email=${getUserEmail()}&notification_type=all`, {})
      .then(res => {
        dispatch(
          fetchNotificationsSuccess(res.data?.data, ACTION.FETCH_NOTIFICATION_SUCCESS)
        );
      })
      .catch(err => {
        if (err?.response?.status === 401) {
          clearAll();
          window.location.reload();
        }
      });
  };
}
export const fetchNotificationsSuccess = (notification, type) => ({
  type: type,
  payload: notification
});

export function readNotification(id) {
  return dispatch => {
    return API.patch(`${API_NOTIFICATION}?operation_type=read`, { notification_ids: [id] }, {})
      .then(res => {
        dispatch(
          readNotificationSuccess(res.data?.data, ACTION.READ_NOTIFICATION_SUCCESS)
        );
        dispatch(fetchNotifications());
      })
      .catch(err => {
        dispatch(readNotificationError(err));
      });
  };
}

export const readNotificationSuccess = list => ({
  type: ACTION.READ_NOTIFICATION_SUCCESS,
  payload: list
});

export const readNotificationError = () => ({
  type: ACTION.READ_NOTIFICATION_ERROR,
  error: "Unable to read notification! please try after sometime."
});

export function saveTags(tags) {
  return dispatch => {
    dispatch(saveTagsBegin());
    return API.post(`${API_TAGS}`, tags, {})
      .then(res => {
        dispatch(saveTagsSuccess(res));
      })
      .catch(err => {
        dispatch(saveTagsError(err));
      });
  };
}

export const saveTagsBegin = () => ({
  type: ACTION.SAVE_TAGS_BEGIN
});

export const saveTagsSuccess = list => ({
  type: ACTION.SAVE_TAGS_SUCCESS,
  payload: list
});

export const saveTagsError = () => ({
  type: ACTION.SAVE_TAGS_ERROR,
  error: "Unable to save tags! please try after sometime."
});

export function clearAllNotifications(notifications) {
  let ids = notifications.map(item => {
    return item.id;
  });
  return dispatch => {
    dispatch(clearAllNotificationsBegin());
    return API.patch(
      `${API_NOTIFICATION}?operation_type=cleared`,
      { notification_ids: ids },
      {}
    )
      .then(res => {
        dispatch(clearAllNotificationsSuccess(res));
      })
      .catch(err => {
        dispatch(clearAllNotificationsError(err));
      });
  };
}

export const clearAllNotificationsBegin = () => ({
  type: ACTION.CLEAR_NOTFN_BEGIN
});

export const clearAllNotificationsSuccess = list => ({
  type: ACTION.CLEAR_NOTFN_SUCCESS,
  payload: list
});

export const clearAllNotificationsError = () => ({
  type: ACTION.CLEAR_NOTFN_ERROR,
  error: "Unable to clear notification! please try after sometime."
});
