import React, { useState, useEffect } from "react";
import "../style.css";
import { get } from "lodash";
import {
  fetchProjects,
  share,
  deleteUser,
  deleteProject,
  fetchOrganization,
  fetchDepartment,
  setSelectedProject,
  fetchMetadata,
  fetchNotifications,
  readNotification,
  saveProject,
  clearAllNotifications,
  fetchAccessibleClients,
  fetchFrequency,
  fetchEngine,
  fetchLanguage,
  fetchTags,
  fetchDevices,
} from "../../redux/rankTracking/action";
import { connect } from "react-redux";
import { getUserEmail } from "../../helpers/utility";
import Projects from "../Projects/Projects";
import { Tabs, Tab, Badge, Grid, Paper } from "@material-ui/core";
import Upload from "../Upload/Upload";
import UploadHistory from "../Upload/UploadHistory";
import Notification from "../Notification/Notification";
import TabPanel from "../shared/TabPanel";
import "font-awesome/css/font-awesome.min.css";
import SplashScreen from "./SplashScreen";
import CustomToolTip from "../shared/CustomToolTip";
import { PrimaryButton2, RankTrackingWrapper } from "./style";
import FilterPopup from "../Projects/FilterPopup";

const RankTracking = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [projectArray, setProjectArray] = useState([]); //dropdown options
  const [clientArray, setClientArray] = useState([]); //dropdown options
  const [projectFilteredClents, setProjectFilteredClents] = useState([]); //list of clients whose project are filtered
  const [filterPopupVisible, setFilterPopupVisible] = useState(null);
  const [allProject, setAllProjects] = useState([]);
  const { filteredClient,  setFilteredClient, filteredProjects, setFilteredProjects} = props;

  // useEffect(() => {
  //   let diff = new Date().getTime() - getSetupTime();
  //   let hours = 24 * 60 * 60 * 1000;
  //   if (diff > hours) {
  //     clearAll();
  //     window.location.reload();
  //   }
  // }, []);
  useEffect(() => {
    props.fetchProjects(props.userEmail);
    props.fetchAccessibleClients(props.userEmail);
    props.fetchOrganization();
    props.fetchDepartment();
    props.fetchMetadata(props.userEmail);
    props.fetchNotifications();
  }, [props.userEmail]);

  useEffect(() => {
    props.fetchFrequency();
    props.fetchEngine();
    props.fetchLanguage();
    props.fetchDevices();
  }, [props.token]);

  useEffect(() => {
    const interval = setInterval(() => {
      props.fetchNotifications();
    }, 30000);
    return () => {
      clearInterval(interval);
      props.setSelectedProject("");
    };
  }, []);

  const { selectedProject, metadata, count, projects } = props;
  let metaDataForProject = {};
  if (selectedProject) {
    metaDataForProject = get(metadata, selectedProject.project_id, {});
  }
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  // const signOut = () => {
  //   clearAll();
  //   props.firebase.auth().signOut();
  //   window.location.reload();
  // };

  useEffect(() => {
    if (projects.length > 0) {
      // populating client dropdown
      setClientArray(
        projects.map((clients) => ({
          label: clients?.organization,
          value: clients?.organization_id,
        }))
      );
    }
  }, [projects]);

  useEffect(() => {
    // populating project dropdown
    let projectlist = [];
    let allP = [];
    projects.forEach((clients) => {
      const projects = [
        ...clients?.data?.map((project) => ({
          label: project?.project,
          value: project?.project_id,
          client: clients?.organization,
          org_id: clients?.organization_id,
        })),
      ];
      allP = [...allP, ...projects];
      if (filteredClient.length > 0) {
        if (filteredClient.includes(clients?.organization_id))
          projectlist = [...projectlist, ...projects];
      } else projectlist = [...projectlist, ...projects];
      setProjectArray(projectlist);
    });
    setAllProjects(allP);
  }, [projects, filteredClient, filteredProjects]);

  useEffect(() => {
    let clientList = [];
    projects.forEach((org) => {
      if (org.data.some((pr) => filteredProjects.includes(pr.project_id))) {
        clientList.push(org.organization_id);
      }
    });
    setProjectFilteredClents(clientList);
    //setFilteredClient(filteredClient.concat(clientList.filter((item) => filteredClient.indexOf(item) < 0)))
  }, [filteredProjects]);

  const id = filterPopupVisible ? 'simple-popover' : undefined;

  return (
    <RankTrackingWrapper>
      <div className="rank-tracking-wrapper">
        <section className="outer-wrapper">
          <Grid container className="full-wrapper">
            <Grid item xs={12} sm={12} md={4} xl={2} lg={3}>
              <Grid container component={Paper} style={{ height: "100%", minHeight: "100vh" }}>
                <Grid item xs={12}>
                <Grid container>
                <Grid item xs={12}>
                  <div className="choose-project left-inner-container">
                    Choose Your Project
                    <div className="m-t-1">
                      Select your client and project from the dropdown or browse
                      from the list below.
                                    </div>
                    <div className="m-t-1">
                      Light gray indicates an inactive
                      client.
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} style={{ position: "relative" }}>
                  <div
                    aria-describedby={id}
                    className="left-inner-container filter-container"
                    style={filterPopupVisible ? {
                      background: "black",
                      color: "white",
                      border: "2px solid #2f2f2f"
                    } : {}}
                    onClick={(event) => {
                      setFilterPopupVisible(filterPopupVisible ? null : event.currentTarget);
                      props.clearAll(); //filter pane to reset each time
                    }}
                  >
                    <img className="filter-closeIcon" alt="closeIcon" src={filterPopupVisible ? "/images/Icon_Close.png" : "/images/Icon_Filter.png"} />
                    <span className="font-bold-700">FILTERS</span>
                  </div>
                  <FilterPopup
                    filterPopupVisible={Boolean(filterPopupVisible)}
                    setFilterPopupVisible={setFilterPopupVisible}
                    clientArray={clientArray}
                    filteredClientData={filteredClient}
                    projects={projects}
                    filteredProjectData={filteredProjects}
                    allProject={allProject}
                    projectArray={projectArray}
                    {...props}
                    setFilteredClientData={setFilteredClient}
                    setFilteredProjectData={setFilteredProjects}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Projects
                    {...props}
                    projectFilteredClents={projectFilteredClents}
                    setProjectArray={setProjectArray}
                    onCloseChip={(clients) => {
                      setFilteredClient(prev => {
                        const index = prev.indexOf(clients.organization_id);
                        if (index > -1) { 
                          prev.splice(index, 1); 
                        }
                        return prev;
                      });
                      setFilteredProjects(prj => {
                        const clientPrj = clients.data;
                        const filterData = prj.filter(ele => !clientPrj.find(e => e.project_id === ele));
                        return filterData;
                      });
                    }}
                  />
                </Grid>   
              </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={8} xl={10} lg={9}>
              <Grid container justify="center">
                <Grid item xs={11}>
                  <Grid container>
                    <Grid item xs={12}>
                      <section className="header">
                        <div
                          className="notification-wrapper"
                          onClick={() => setNotificationModalOpen(true)}
                        >
                          <span className="alert-label"> Alerts</span>
                          <Badge
                            badgeContent={count}
                            color="error"
                            className="notification-badge"
                          >
                            <img
                              className="notification-bell-icon"
                              alt="notification-bell-icon"
                              src={"/images/Notification_Icon.svg"}
                            />
                          </Badge>
                        </div>
                      </section>
                    </Grid>
                    <Grid item xs={12}>
                     {get(props, ["selectedProject", "project_id"], "") ? (
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={5}>
                                <p className="client-label">CLIENT NAME</p>
                                <p className="project-label">{selectedProject && selectedProject.organization}</p>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
                              <div style={{ display: "flex" }}>
                                <p className="client-label">TOTAL ACTIVE SEARCH TERMS</p>
                                <CustomToolTip title="The total search terms being tracked, by frequency." />
                              </div>
                              <Grid container>
                                <Grid item xs={2}>
                                  <p className="count margin0">{get(metaDataForProject, "daily_count", 0)}</p>
                                  <p className="count-label">Daily</p>
                                </Grid>
                                <Grid item xs={2}>
                                  <p className="count margin0">{get(metaDataForProject, "weekly_count", 0)}</p>
                                  <p className="count-label">Weekly</p>
                                </Grid>
                                <Grid item xs={2}>
                                  <p className="count margin0">{get(metaDataForProject, "monthly_count", 0)}</p>
                                  <p className="count-label">Monthly</p>
                                </Grid>
                                <Grid item xs={2}>
                                  <p className="count margin0">{get(metaDataForProject, "quarterly_count", 0)}</p>
                                  <p className="count-label">Quarterly</p>
                                </Grid>
                                <Grid item xs={2}>
                                  <p className="count margin0">{get(metaDataForProject, "annually_count", 0)}</p>
                                  <p className="count-label">Annually</p>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={5} style={{ marginTop: "0.2rem"}}>
                              <p className="client-label">Division</p>
                              <p className="project-label">{selectedProject?.department || "Not selected"}</p>
                            </Grid>
                            <Grid item xs={7} style={{ marginTop: "0.2rem"}}>
                              <div style={{ display: "flex" }}>
                                <p className="client-label">Tracked Client Site</p>
                                <CustomToolTip title="We track organic rankings based on the website you entered when creating this project. If you want to track search terms for another website, create a new project." />
                              </div>
                              <p className="project-label">{selectedProject?.domain || "Not selected"}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} className="margin-top30">
                          <div className="box-wrapper right-bottom-wrapper margin-bottom-50">
                            <Tabs
                              value={selectedTab}
                              onChange={handleChange}
                              indicatorColor="primary"
                              className="tab-wrapper"
                              TabIndicatorProps={{
                                style: {
                                  background: "none",
                                },
                              }}
                            >
                              <Tab
                                label="Upload/Edit Search Terms"
                                className="upload-tabs"
                              />
                              <Tab label="View History" className="upload-tabs" />
                            </Tabs>
                            <div className="layout-style">
                              <TabPanel value={selectedTab} index={0}>
                                <Upload {...props} />
                              </TabPanel>
                              <TabPanel value={selectedTab} index={1}>
                                <UploadHistory {...props} />
                              </TabPanel>
                            </div>
                            <div className="bottom-bar"></div>
                          </div>
                        </Grid>
                      </Grid>
                      ) : (
                        <SplashScreen />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </section>
        <Notification
          {...props}
          notificationModalOpen={notificationModalOpen}
          setNotificationModalOpen={setNotificationModalOpen}
        />
        {/* <Footer /> */}
      </div>
    </RankTrackingWrapper>
  );
};

export default connect(
  (state) => ({
    notification: state.Dashboard.notification,
    metadata: state.Dashboard.metadata,
    error: state.Dashboard.error,
    clients: state.Dashboard.organization,
    divisions: state.Dashboard.departments,
    projects: state.Dashboard.projects,
    status: state.Dashboard.status,
    project_loading: state.Dashboard.project_loading,
    org_dept_loading: state.Dashboard.org_dept_loading,
    delete_loading: state.Dashboard.delete_loading,
    project_delete_error: state.Dashboard.project_delete_error,
    selectedProject: state.Dashboard.selectedProject,
    savedProjectId: state.Dashboard.savedProjectId,
    count: state.Dashboard.count,
    share_status: state.Project.share_status,
    isLoadingCreateProject: state.Dashboard.isLoadingCreateProject,
    isCreatedProject: state.Dashboard.isCreatedProject,
    isClearNotificationLoading: state.Dashboard.isClearNotificationLoading,
    accessible_clients: state.Dashboard.accessible_clients,
    frequencies: state.Dashboard.frequencies,
    downloadOptions: state.Dashboard.downloadOptions,
    engines: state.Dashboard.engines,
    devices: state.Dashboard.devices,
    locales: state.Dashboard.locale,
    tags: state.Dashboard.tags,
    userEmail: getUserEmail(),
  }),
  {
    fetchProjects,
    share,
    deleteProject,
    deleteUser,
    saveProject,
    fetchNotifications,
    setSelectedProject,
    readNotification,
    fetchOrganization,
    fetchDepartment,
    fetchMetadata,
    clearAllNotifications,
    fetchAccessibleClients,
    fetchFrequency,
    fetchEngine,
    fetchLanguage,
    fetchTags,
    fetchDevices,
  }
)(RankTracking);
